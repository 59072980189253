<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-semibold p-4 border-b-2 border-red-600">
        Compensation Benchmarking
      </h2>

      <img
        src="../../assets/img/comp_benchmark.jpg"
        alt="Compensation Benchmarking Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="py-4">
          HR plays a key role in fostering a vibrant, competitive and productive workforce.
          Effective design of salary and benefits plans is critical to attracting the best
          candidate for each position, facilitating internal transfers and promotions and
          recognising and rewarding performance.
        </p>
        <p class="py-1">
          Some of your considerations may include
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Job grading or banding</li>
          <li>Single or Multiple salary structuring</li>
          <li>Salary benchmarking</li>
          <li>Benefits plan</li>
        </ul>
        <p class="py-1">
          Leveraging on our job evaluation methodology,
          <strong>Job Evaluation System (JES)</strong>, MRC render assistance on:
        </p>
        <ul class="px-4 pb-4 list-disc text-red-700">
          <li>Salary structure design</li>
          <li>Organisation structure design</li>
          <li>Role clarification & position evaluation</li>
        </ul>
      </div>

      <img
        src="../../assets/img/compensation_benchmarking_chart.jpg"
        alt="Compensation Benchmarking Chart"
        class="object-cover w-full lg:w-3/4 md:w-4/5 sm:p-4"
      >

      <p class="p-4">
        To learn how MRC and you can work together through your understanding of your company
        and leveraging on our consulting expertise to effectively design salary and benefits,
        please contact us to speak with one of our consultants.
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
